<template>
  <div class="login-wrapper">
    <h1>Вход инфо панель</h1>
    <form class="form" method="post" action="/login/">
      <label class="label" for="username">Логин</label>
      <input class="input" name="login" id="username" v-model="username" />
      <label class="label" for="password">Пароль</label>
      <input
        type="password"
        name="password"
        class="input"
        id="password"
        v-model="password"
      />

      <button class="submit" aria-label="log in">Вход</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: ""
    };
  }
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-radius: 4px;
  width: 600px;
  min-height: 400px;
  padding: 60px calc(20px + 60px) 60px 60px;
  &::after {
    top: 0;
    right: 0;
    position: absolute;
    content: "";
    height: 100%;
    width: 20px;
    background-color: #4285f4;
    border-radius: 0 4px 4px 0;
  }
}

h1 {
  color: #4285f4;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 35px 0;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  align-self: start;
  letter-spacing: 1.2px;
}

input.input,
input[type="password"].input {
  padding: 5px 15px;
  margin: 5px 0 35px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 4px;
  border: 3px solid transparent;
  height: 50px;
  box-sizing: border-box;
  outline: none;
  transition: 0.3s;

  &:focus {
    background-color: white;
    border: 3px solid #4285f4;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
}

.submit {
  bottom: 0;
  margin-top: 25px;
  background-color: #4285f4;
  font-weight: 400;
  font-size: 25px;
  color: white;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  transition: 0.3s;
  outline: none;
  &:hover {
    background-color: rgba(66, 133, 244, 0.8);
  }
}
</style>
